html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

#root {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: large;
}

.game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
}

@keyframes mistakeAnimation {
  0% {
    background-color: #bfbfbf;
  }
  25% {
    background-color: #ff9797;
  }
  75% {
    background-color: #ff9797;
  }
  100% {
    background-color: #ebebeb;
  }
}

@keyframes correctAnimation {
  0% {
    opacity: 25%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes alertAnimation {
  0% {
    top: -100px;
    opacity: 100%;
  }
  50% {
    top: 0px;
    opacity: 100%;
  }
  75% {
    top: 0px;
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

.tile {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  text-align: center;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.selectedTile {
  background-color: #bfbfbf;
}

.mistakeAnimation {
  animation: mistakeAnimation 0.75s;
}

.answerOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-size: larger;
  font-style: italic;
  overflow-wrap: break-word;
  animation: correctAnimation 2s ease-out;
}

.yellow {
  background-color: #fff7ac;
}

.green {
  background-color: #c2ffac;
}

.blue {
  background-color: #acf0ff;
}

.orange {
  background-color: #ffdeac;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
  width: 100px;
  justify-content: space-around;
}

.footerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background-color: #d9d9d9;
  font-size: large;
  font-weight: 700;
  border: none;
}

.footerButton:active,
.footerButton:hover {
  background-color: #bfbfbf;
}

.shuffleIcon {
  height: 20px;
  width: 20px;
}

.mistakeTracker {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.mistakeDot {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  margin: 3px;
}

.unfilled {
  background-color: #d9d9d9;
}

.filled {
  background-color: #ff9797;
}

.alert {
  width: fit-content;
  height: 50px;
  border-radius: 10px;
  line-height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  vertical-align: middle;
  font-size: medium;
  font-weight: 700;
  background-color: #000000;
  color: #ffffff;
  z-index: 9;
  position: absolute;
  animation: alertAnimation 1.5s;
}

.alertContainer {
  width: 100%;
  height: 80%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  pointer-events: none;
}

.modalContainer {
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.modal {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #000000;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  width: 300px;
  animation: correctAnimation 0.75s;
}

.modalContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.modalTitle {
  font-size: x-large;
  font-weight: bolder;
  margin-bottom: 20px;
}

.modalBody {
  white-space: pre-line;
  overflow-wrap: break-word;
  font-size: large;
}

.resultBodyText {
  text-align: center;
  font-size: larger;
  margin-bottom: 20px;
}

.exitButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 300px;
}

.exitButton:active,
.exitButton:hover {
  color: #000000;
}

.modalButton {
  width: 140px;
  height: 35px;
  border-radius: 10px;
  outline: #000000;
  background-color: #ffffff;
  z-index: 9;
}

.modalButton:active,
.modalButton:hover {
  background-color: #ebebeb;
}

.exitButton {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background-color: #ffffff;
  color: #bfbfbf;
  font-size: large;
  font-weight: bolder;
  border: none;
}

.contactBody {
  margin-top: 20px;
}

a {
  color: #000000;
}

.answerOverlay {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: medium;
}

.answerTitle {
  font-size: large;
}

.answerLyric {
  font-size: small;
}

@media screen and (max-width: 600px) {
  .game {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .header {
    justify-content: space-between;
    height: 150px;
    width: 440px;
  }

  .tile {
    height: 100px;
    width: 100px;
    border-radius: 7px;
    margin: 3px;
    padding: 3px;
    font-size: medium;
  }

  .answerOverlay {
    height: 100px;
    width: 440px;
    border-radius: 7px;
    margin: 3px;
  }

  .footer {
    width: 430px;
  }
}

@media screen and (max-width: 400px) {
  .header {
    justify-content: space-between;
    height: 150px;
    width: 375px;
    margin-bottom: 20px;
  }

  .tile {
    height: 80px;
    width: 80px;
    border-radius: 7px;
    margin: 3px;
    padding: 3px;
    font-size: small;
  }

  .answerOverlay {
    height: 80px;
    width: 360px;
    border-radius: 7px;
    margin: 3px;
  }

  .footer {
    width: 350px;
  }
}

@media screen and (min-width: 600px) {
  .game {
    margin: 5px;
  }
  .header {
    justify-content: center;
    width: 500px;
  }

  .tile {
    height: 115px;
    width: 115px;
    border-radius: 10px;
    margin: 5px;
    padding: 5px;
  }

  .answerOverlay {
    height: 125px;
    width: 535px;
    border-radius: 10px;
    margin: 5px;
  }

  .footer {
    width: 500px;
  }
}
